import { parseQueryString } from "./utils";
import {
  addUtmQuerystringsToLocalStorage,
  utm,
  utm_source,
  utm_campaign,
  utm_content,
  utmMapping,
} from "./utmTracking";

declare var fieldIdsToHide: string; // eslint-disable-line no-var
declare var MsCrmMkt: any; // eslint-disable-line no-var
declare let submitEvent: any; // eslint-disable-line
declare let grecaptcha: any; // eslint-disable-line

const dynamicsFormQuerySelectorMappings: { [key: string]: string } = {
  [utm]: "input[name='c09ef87b-eb7a-eb11-a812-0022481a889a']",
  [utm_source]: "select[name='60a02fc6-b759-ec11-8f8f-000d3a0d0cfb']",
  [utm_campaign]: "input[name='43bbad09-9061-ec11-8f90-0022481b463c']",
  [utm_content]: "input[name='f516b5f6-8f61-ec11-8f90-0022481b463c']",
};

addUtmQuerystringsToLocalStorage();

let querystringParams = parseQueryString();

let utmCounter = 0;

const populateUtmFields = () => {
  for (let querystringKey in utmMapping) {
    const localStorageVal = localStorage.getItem(utmMapping[querystringKey]);
    const qsVal = querystringParams.get(querystringKey);
    let utmVal = qsVal || localStorageVal;
    if (querystringKey === "utm") {
      utmVal = utmVal || window.location.search;
      let utmFeedback = "";
      let utmSource = localStorage.getItem("lodlawMarketingUtmSource");
      if (utmSource) {
        utmFeedback += `?utm_source=${utmSource}`;
      }
      let utmMedium = localStorage.getItem("lodlawMarketingUtmMedium");
      if (utmMedium) {
        utmFeedback += `&utm_medium=${utmMedium}`;
      }
      let utmCampaign = localStorage.getItem("lodlawMarketingUtmCampaign");
      if (utmCampaign) {
        utmFeedback += `&utm_campaign=${utmCampaign}`;
      }
      let utmContent = localStorage.getItem("lodlawMarketingUtmContent");
      if (utmContent) {
        utmFeedback += `&utm_content=${utmContent}`;
      }
      utmVal = utmVal || utmFeedback;
    }

    if (utmVal) {
      let inputArr = document.querySelectorAll(
        dynamicsFormQuerySelectorMappings[querystringKey],
      );
      if (querystringKey == "utm") {
        if (inputArr.length > 0) {
          for (let input of inputArr) {
            if (input.tagName === "SELECT") {
              for (let option of (<HTMLSelectElement>input).options) {
                if (option.text === utmVal) {
                  (<HTMLSelectElement>input).value = option.value;
                }
              }
            } else {
              (<HTMLInputElement>input).value = utmVal;
            }
          }
        } else if (utmCounter < 10) {
          utmCounter++;
          setTimeout(populateUtmFields, 200);
        }
      } else {
        for (let input of inputArr) {
          if (input.tagName === "SELECT") {
            for (let option of (<HTMLSelectElement>input).options) {
              if (option.text === utmVal) {
                (<HTMLSelectElement>input).value = option.value;
              }
            }
          } else {
            (<HTMLInputElement>input).value = utmVal;
          }
        }
      }
    }
  }
};

const populateReferrerFields = () => {
  let referrer = localStorage.getItem("referrer");
  if (referrer) {
    let inputArr = document.querySelectorAll(
      "input[name='79d91816-0286-eb11-a812-0022481a7ba8']",
    );
    for (let input of inputArr) {
      (<HTMLInputElement>input).value = referrer;
    }
  }
};

const removeSelectPlaceholdersOnChange = () => {
  for (let select of document.querySelectorAll("select")) {
    select.addEventListener("change", function () {
      this.classList.remove("selectPlaceholder");
    });
  }
};

const udpateDynamicsFormSyles = () => {
  let fullWidthFieldContainer = <any>(
    document.getElementById("23565bd8-b759-ec11-8f8f-000d3a0d0cfb")
  );
  let count = 0;
  // Iterate updwards through DOM, setting max width of parents to 100%
  while (
    fullWidthFieldContainer &&
    fullWidthFieldContainer.tagName !== "FORM" &&
    count++ < 9
  ) {
    fullWidthFieldContainer.style["max-width"] = "100%";
    fullWidthFieldContainer = fullWidthFieldContainer?.parentNode;
  }
};

const hideFormFields = () => {
  if (typeof fieldIdsToHide === "undefined") {
    return;
  }

  const fieldsToHideArray = fieldIdsToHide
    .split(",")
    .filter((fieldId) => fieldId !== "")
    .map((fieldId) => fieldId.trim());

  fieldsToHideArray.forEach((fieldId) => {
    document
      .querySelectorAll(`input[id='${fieldId}'], label[for='${fieldId}']`)
      .forEach((field) => {
        field.remove();
      });
  });
};

const insertCaptchaDiv = () => {
  const parentDiv = document.querySelector(`div[data-form-block-id]`);

  if (parentDiv) {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.src = "https://www.google.com/recaptcha/api.js";
    recaptchaScript.async = true;
    recaptchaScript.defer = true;

    const recaptchaDiv = document.createElement("div");
    recaptchaDiv.classList.add("g-recaptcha");
    recaptchaDiv.dataset.sitekey = "6LdkYlYnAAAAABnuEZgR8jKv92q33r2upUOe_z92";
    recaptchaDiv.style.margin = "20px 0";
    recaptchaDiv.dataset.action = "SUBMIT";

    parentDiv.appendChild(recaptchaScript);
    parentDiv.appendChild(recaptchaDiv);
  } else {
    setTimeout(insertCaptchaDiv, 200);
  }
};

const requireCaptchaToSubmit = () => {
  // @ts-ignore
  let dynamicsButton = document.getElementById("cloned-dynamics-button");

  if (dynamicsButton) {
    const getCaptchaResponse = async () => {
      if (!requiredFieldsCheck()) {
        return false;
      }

      // Collect reCAPTCHA response
      grecaptcha.ready(async function () {
        const token = grecaptcha.getResponse();

        if (token !== "" && token !== null && token !== undefined) {
          const isValid = await validateCaptcha(token);

          if (isValid) {
            let requiredFieldsAlert = document.getElementById(
              "required-fields-alert"
            );
            if (requiredFieldsAlert) {
              requiredFieldsAlert.style.display = "none";
            }

            // @ts-ignore
            let originalButton: HTMLElement = document.getElementsByClassName(
              "lp-form-button lp-form-fieldInput"
            )[0];
            originalButton.click();
          }
        } else {
          showOrCreateRequiredFieldsAlert();
        }
      });
    };

    // @ts-ignore
    dynamicsButton.addEventListener("click", function (event) {
      event.preventDefault();
      getCaptchaResponse();
    });
  } else {
    setTimeout(requireCaptchaToSubmit, 200);
  }
};

const validateCaptcha = async (token: string) => {
  try {
    const data = await $.ajax({
      url: "/api/resources/validate_captcha/",
      data: {
        token: token,
      },
      dataType: "json",
    });

    return data["success"];
  } catch (error) {
    return false;
  }
};

const requiredFieldsCheck = () => {
  let requiredFields = document.querySelectorAll("[aria-required='true']");
  let requiredFieldsArray = Array.from(requiredFields);
  // @ts-ignore
  let requiredFieldsFilled = requiredFieldsArray.every(
    // @ts-ignore
    (field: HTMLInputElement) => {
      if (field.type === "checkbox") {
        return field.checked;
      } else {
        return field.value !== "";
      }
    }
  );
  if (!requiredFieldsFilled) {
    showOrCreateRequiredFieldsAlert();
    return false;
  }
  return true;
};

const showOrCreateRequiredFieldsAlert = () => {
  let requiredFieldsAlert = document.getElementById("required-fields-alert");
  if (!requiredFieldsAlert) {
    addRequiredFieldsAlert();
  }
};

const addRequiredFieldsAlert = () => {
  const submitButton = document.querySelector(
    '[data-editorblocktype="SubmitButtonBlock"]'
  );
  // @ts-ignore
  const grandparent = submitButton.parentElement?.parentElement?.parentElement;
  let requiredFieldsAlertElement = document.createElement("div");
  requiredFieldsAlertElement.textContent =
    "Please fill out all fields marked with an asterisk, and the captcha.";
  requiredFieldsAlertElement.id = "required-fields-alert";
  requiredFieldsAlertElement.style.color = "#ec5f66";
  requiredFieldsAlertElement.style.fontSize = "21px";
  requiredFieldsAlertElement.style.lineHeight = "130%";
  requiredFieldsAlertElement.style.marginBottom = "20px";
  // @ts-ignore
  grandparent.parentElement?.insertBefore(
    requiredFieldsAlertElement,
    // @ts-ignore
    grandparent
  );
};

const cloneSubmitButton = () => {
  // @ts-ignore
  const originalButton: HTMLElement = document.getElementsByClassName(
    "lp-form-button lp-form-fieldInput"
  )[0];
  if (originalButton) {
    // Create a clone of the original button
    const clonedButton = originalButton.cloneNode(true) as HTMLButtonElement;

    // Remove the type attribute from the cloned button
    clonedButton.removeAttribute("type");
    clonedButton.type = "button";
    clonedButton.removeAttribute("name");
    clonedButton.id = "cloned-dynamics-button";

    // Insert the cloned button right next to the original button
    originalButton.parentNode?.insertBefore(
      clonedButton,
      originalButton.nextSibling
    );

    // Hide the original button
    originalButton.style.display = "none";
  } else {
    setTimeout(cloneSubmitButton, 200);
  }
};

const positionCaptcha = () => {
  let outerFormElement = document.getElementsByClassName("show-form")[0];
  if (outerFormElement) {
    let formFields: any = outerFormElement.children[1];
    let captchaElement = document.getElementsByClassName("g-recaptcha")[0];
    formFields.children[formFields.children.length - 3].appendChild(
      captchaElement
    );
  } else {
    setTimeout(positionCaptcha, 200);
  }
};

const showDynamicsForms = () => {
  let dynamicsForms = document.querySelectorAll('form[data-container="true"]');
  if (dynamicsForms.length > 0) {
    dynamicsForms.forEach((form) => {
      form.classList.add("show-form");
    });
  } else {
    setTimeout(showDynamicsForms, 200);
  }
};

const updateDynamicsForms = () => {
  udpateDynamicsFormSyles();
  insertCaptchaDiv();
  showDynamicsForms();
  removeSelectPlaceholdersOnChange();
  hideFormFields();
  cloneSubmitButton();
  requireCaptchaToSubmit();
  positionCaptcha();
  populateUtmFields();
  populateReferrerFields();
};

if (typeof MsCrmMkt !== "undefined") {
  let dynamicsFormsUpdateCalled = false;
  MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", () => {
    if (!dynamicsFormsUpdateCalled) {
      dynamicsFormsUpdateCalled = true;
      updateDynamicsForms();
    }
  });
  window.addEventListener("load", () => {
    if (!dynamicsFormsUpdateCalled) {
      dynamicsFormsUpdateCalled = true;
      updateDynamicsForms();
    }
  });
}
